import { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import axios from 'axios';
import { MdAdd, MdDelete, MdWifi } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import configData from '../../config/config';
import { axiosError } from '../../utilities';

function DeviceList(props) {
  const [allDevices, setAllDevices] = useState([]);
  const [environmentDevices, setEnvironmentDevices] = useState({});
  const [environmentConnectedDevices, setEnvironmentConnectedDevices] =
    useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAllDevices() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/device/rasp/' + props.idEnvironment,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setAllDevices(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }
    async function fetchEnvironmentDevices() {
      try {
        const response = await axios.get(
          configData.SERVER_URL +
            '/environment/' +
            props.idEnvironment +
            '/device',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setEnvironmentDevices(data);
        props.deviceIsEmpty(false);
      } catch (error) {
        if (error.response.status === 404) {
          setEnvironmentDevices(undefined);

          if (props.deviceIsEmpty !== undefined) {
            props.deviceIsEmpty(true);
          }
        } else {
          console.error(error);
        }
      }
    }
    async function fetchEnvironmentConnectedDevices() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/device/connected/' + props.idEnvironment,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setEnvironmentConnectedDevices(data);
      } catch (error) {
        if (!(error && error.response && error.response.status === 404)) {
          axiosError(navigate, error);
        }
      }
    }

    fetchAllDevices();
    fetchEnvironmentDevices();
    fetchEnvironmentConnectedDevices();
  }, [props.idEnvironment, navigate, props]);

  async function addDeviceToEnvironment(idEnvironment, idDevice) {
    try {
      await axios
        .post(
          configData.SERVER_URL + '/device/' + idDevice + '/' + idEnvironment,
          {},
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  async function removeDeviceFromEnvironment(idEnvironment, idDevice) {
    try {
      await axios
        .delete(
          configData.SERVER_URL + '/device/' + idDevice + '/' + idEnvironment,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  function isConnected(id) {
    return Number(environmentConnectedDevices.id) === Number(id);
  }

  async function startScanBle(id) {
    try {
      // signal to parent that a scan is executing
      props.startBle(true);

      await axios
        .get(
          configData.SERVER_URL +
            '/device/' +
            id +
            '/ble/discovery/' +
            props.idEnvironment,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          props.startBle(false);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Hostname</th>
          <th>Private IP</th>
          <th>Type</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {allDevices &&
          allDevices
            .sort((a, b) =>
              a.deviceName > b.deviceName
                ? 1
                : a.deviceName < b.deviceName
                  ? -1
                  : 0,
            )
            .map((u) => (
              <tr key={u.id}>
                <td>{u.deviceName}</td>
                <td>{u.ip}</td>
                <td>{u.type}</td>
                <td>
                  {!environmentDevices && <p>Not associated</p>}

                  {environmentDevices && environmentDevices.id === u.id && (
                    <p>Associated</p>
                  )}
                </td>
                <td>
                  {isConnected(u.id) && (
                    <Button
                      onClick={() => startScanBle(u.id)}
                      disabled={props.disabled}>
                      <MdWifi /> Scan ble
                    </Button>
                  )}
                  {environmentDevices && environmentDevices.id === u.id && (
                    <Button
                      variant="danger"
                      onClick={() =>
                        removeDeviceFromEnvironment(props.idEnvironment, u.id)
                      }
                      className="btn-danger"
                      disabled={props.disabled}>
                      <MdDelete /> Disassociate
                    </Button>
                  )}
                  {!environmentDevices && (
                    <Button
                      variant="success"
                      onClick={() =>
                        addDeviceToEnvironment(props.idEnvironment, u.id)
                      }
                      className="btn-primary"
                      disabled={props.disabled || u.isCollecting}>
                      <MdAdd /> Associate
                    </Button>
                  )}
                  {environmentDevices &&
                    environmentDevices.id !== null &&
                    environmentDevices.id !== u.id && (
                      <Button
                        variant="success"
                        onClick={() =>
                          addDeviceToEnvironment(props.idEnvironment, u.id)
                        }
                        className="btn-primary"
                        disabled>
                        <MdAdd /> Associate
                      </Button>
                    )}
                </td>
              </tr>
            ))}
      </tbody>
    </Table>
  );
}

export default DeviceList;
