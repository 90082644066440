import { Outlet, Link } from 'react-router-dom';
import { Card, Container, Button } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';

import { FaInfoCircle } from 'react-icons/fa';
import EnvironmentList from '../components/environment/EnvironmentList';

function Environments() {
  return (
    <>
      <Container>
        <br />
        <h1>Enviornment List</h1>
        <br />
        <p className="grayText">
          <FaInfoCircle /> Click on an environment to manage it. You can also
          add a new one or delete an old one.
        </p>
        <br />
        <Card className="overflow-auto">
          <Card.Header>Your environment</Card.Header>
          <Card.Body>
            <EnvironmentList />

            <hr />
            <Link to={'/dashboard/environments/create'}>
              <Button variant="success">
                <MdAdd /> Add New
              </Button>
            </Link>
          </Card.Body>
        </Card>
      </Container>

      <Outlet />
    </>
  );
}

export default Environments;
