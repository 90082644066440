import Swal from 'sweetalert2';

export function IsBeta() {
  return process.env.REACT_APP_ISBETA !== 'FALSE';
}

export function GetTopBarString() {
  return process.env.REACT_APP_TOPBARSTRING || 'BETA VERSION';
}

export function ShowAlert(error = 'Generic error') {
  Swal.fire({
    icon: 'error',
    title: 'Something went wrong!',
    text: error,
  });
}

export function axiosError(navigate, error) {
  if (error.response === undefined) {
    ShowAlert(error.message);
    console.error(error);

    navigate('/redirect');
  } else if (error.response !== undefined && error.response.status === 401) {
    navigate('/login');
  } else {
    ShowAlert(error.message);
    console.error(error);
  }
}
