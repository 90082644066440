import { useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';

import constants from '../config/constant';

function RedirectPage() {
  const navigate = useNavigate();

  useEffect(() => {
    function redirectNow() {
      navigate('/');
    }

    setTimeout(redirectNow, constants.redirectPageMs);
  }, [navigate]);

  return (
    <>
      <Outlet />

      <Link to="/">
        <p>Redirect to login page</p>
      </Link>
    </>
  );
}

export default RedirectPage;
