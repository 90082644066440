import EnvironmentNode from './EnvironmentNode';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { axiosError } from '../../utilities';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';

import styles from './EnvironmentList.module.css';

import configData from '../../config/config';

function EnvironmentList() {
  const [environment, setEnvironment] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getEnvironments() {
      try {
        setShowLoading(true);

        const response = await axios.get(
          configData.SERVER_URL + '/environment',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setEnvironment(data);
        setShowLoading(false);
      } catch (error) {
        setShowLoading(false);
        axiosError(navigate, error);
      }
    }

    getEnvironments();
  }, [navigate, setEnvironment]);

  return (
    <>
      {environment &&
        environment
          .sort((a, b) =>
            a.description > b.description
              ? 1
              : a.description < b.description
                ? -1
                : 0,
          )
          .map((node) => (
            <EnvironmentNode key={node.id} parent={node.id} node={node} />
          ))}

      {showLoading && <div className={styles.backdrop}></div>}
      {showLoading && (
        <div className={styles.spinnerClass}>
          <Oval
            visible="true"
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
    </>
  );
}

export default EnvironmentList;
