import { Outlet, Link } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';
import { FaInfoCircle } from 'react-icons/fa';
import UserList from '../components/user/UserList';
import './../mainStyle.scss';

function Users() {
  return (
    <>
      <Outlet />

      <Container>
        <h2>Create, delete or share users.</h2>
        <br />
        <p className="grayText">
          <FaInfoCircle /> when you create a user, remember to associate the
          wearable device if you want to collect data with it
          <br />
          <FaInfoCircle /> when you share the user, share all the patients he
          creates and their environments
        </p>
        <br />
        <Card className="overflow-auto">
          <Card.Header>Your users</Card.Header>
          <Card.Body>
            <UserList />

            <Link to={'/dashboard/users/create'}>
              <Button variant="primary">
                <MdAdd /> Add New User
              </Button>
            </Link>
          </Card.Body>
        </Card>

        <br />

        <Card className="overflow-auto">
          <Card.Header>Other users</Card.Header>
          <Card.Body>
            <UserList other />
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Users;
