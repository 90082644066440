import { Form, Link, useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { useEffect, useState, useRef } from 'react';

import styles from './NewEnvironment.module.css';

import configData from '../config/config';
import { axiosError } from '../utilities';

function NewUser() {
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);

  let { id } = useParams();

  const handleClose = () => navigate('..');

  useEffect(() => {
    async function fetchUser(id) {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/user/' + id + '/info',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );
        const data = await response.data;

        setIsAdmin(data.isAdmin);
        setUsername(data.username);
        setName(data.name);
        setSurname(data.surname);
        setEmail(data.email);

        if (data.idDevice != null) {
          const respondeDevice = await axios.get(
            configData.SERVER_URL + '/device/info/' + data.idDevice,
            {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem('token')).token
                }`,
              },
            },
          );
          const dataDevice = await respondeDevice.data;

          setDefaultValueSelect({
            value: dataDevice.id,
            label: dataDevice.deviceName,
          });
        }
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    if (id !== undefined) {
      fetchUser(id);
    }
  }, [id, navigate]);

  useEffect(() => {
    async function fetchDevices() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/device/wearos/unused',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;

        let list = [];
        list.push({ value: 0, label: 'Nothing' });
        data.forEach((element) => {
          list.push({ value: element.id, label: element.deviceName });
        });

        setDevices(list);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    fetchDevices();
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let envData = {
      username: username,
      name: name,
      surname: surname,
      email: email,
      password: password,
      isAdmin: isAdmin,
    };

    if (defaultValueSelect.value === '' || defaultValueSelect.value === 0) {
      envData.idDevice = null;
    } else {
      envData.idDevice = defaultValueSelect.value;
    }
    if (password === '') {
      envData.password = null;
    }

    console.log(envData);

    if (id) {
      try {
        await axios.post(configData.SERVER_URL + '/user/' + id, envData, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        });
      } catch (error) {
        axiosError(navigate, error);
      }
    } else {
      try {
        await axios.post(configData.SERVER_URL + '/user', envData, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        });
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    navigate('..');
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [defaultValueSelect, setDefaultValueSelect] = useState({
    value: 0,
    label: 'Nothing',
  });

  function calculateMinPassword() {
    if (isAdmin === false) {
      return 0;
    }

    if (id !== undefined) {
      return 0;
    } else {
      return 8;
    }
  }
  function isPasswordRequired() {
    return id === undefined;
  }

  const ref = useRef();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      ref.current.focus();
    }
  };

  return (
    <>
      <Modal show onHide={handleClose}>
        <Form
          method="post"
          className={styles.form}
          onKeyDown={handleKeyDown}
          onSubmit={handleSubmit}>
          <h2>Create a new user:</h2>
          <hr />
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="isAdmin"
              name="isAdmin"
              value="true"
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            />
            <label htmlFor="isAdmin" className="form-check-label">
              Is admin (nurse, doctor, ...)
            </label>
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              className="form-control"
              id="username"
              name="username"
              placeholder="john.smith"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              placeholder="John"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Surname</label>
            <input
              className="form-control"
              type="text"
              id="surname"
              name="surname"
              placeholder="Smith"
              required
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className="form-control"
              type="email"
              id="email"
              name="email"
              placeholder="john.smith@email.com"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              className="form-control"
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              minLength={calculateMinPassword()}
              required={isPasswordRequired()}
              disabled={isAdmin === false}
            />
          </div>
          <div className="form-group">
            <label htmlFor="id-device">Select device</label>
            <Select
              id="idDevice"
              name="idDevice"
              options={devices}
              value={defaultValueSelect}
              onChange={(e) =>
                setDefaultValueSelect({
                  value: e.value,
                  label: e.label,
                })
              }
              isDisabled={isAdmin}
            />
          </div>
          <br />
          <div>
            <Link to="..">
              <button className="btn btn-danger">Cancel</button>
            </Link>
            <button className="btn btn-primary spl15" type="submit" ref={ref}>
              Submit
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default NewUser;
