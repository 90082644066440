import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import RootLayout from './routes/RootLayout';

import NavBarSite from './routes/dashboard/NavBarSite';
import Login from './routes/Login';

import Environments from './routes/Environments';
import Devices from './routes/Devices';
import Users from './routes/Users';
import EnvironmentsDetails from './routes/EnvironmentsDetails';
import NewEnvironment from './routes/NewEnvironment';
import NewUser from './routes/NewUser';
import Logout from './routes/Logout';
import ChartsDetails from './routes/ChartsDetails';
import Charts from './routes/Charts';
import Export from './routes/Export';
import Credits from './routes/Credits';
import Dashboard from './routes/Dashboard';
import Restore from './routes/Restore';
import RedirectPage from './routes/RedirectPage';
import Diary from './routes/Diary';

import { ErrorBoundary } from 'react-error-boundary';

import './index.css';

// Hide console in production
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.debug = () => {};
}

const ErrorBoundaryLayout = () => (
  <ErrorBoundary errorElement={RedirectPage}>
    <Outlet />
  </ErrorBoundary>
);

const router = createBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    children: [
      {
        path: '/',
        element: <RootLayout />,
        children: [
          {
            path: '/dashboard',
            element: <Dashboard />,
            children: [],
          },
          {
            path: '/dashboard',
            element: <NavBarSite />,
            children: [
              {
                path: '/dashboard/users',
                element: <Users />,
                children: [
                  {
                    path: '/dashboard/users/create',
                    element: <NewUser />,
                  },
                  {
                    path: '/dashboard/users/edit/:id',
                    element: <NewUser />,
                  },
                ],
              },
              {
                path: '/dashboard/environments',
                element: <Environments />,
                children: [
                  {
                    path: '/dashboard/environments/create/:parent',
                    element: <NewEnvironment />,
                  },
                  {
                    path: '/dashboard/environments/create',
                    element: <NewEnvironment />,
                  },
                ],
              },
              {
                path: '/dashboard/devices',
                element: <Devices />,
                children: [],
              },
              {
                path: '/dashboard/charts',
                element: <Charts />,
                children: [],
              },
              {
                path: '/dashboard/charts/:id',
                element: <ChartsDetails />,
                children: [],
              },
              {
                path: '/dashboard/export',
                element: <Export />,
                children: [],
              },
              {
                path: '/dashboard/credits',
                element: <Credits />,
                children: [],
              },
              {
                path: '/dashboard/diary',
                element: <Diary />,
                children: [],
              },
              {
                path: '/dashboard/logout',
                element: <Logout />,
                children: [],
              },
              {
                path: '/dashboard/environments/:id',
                element: <EnvironmentsDetails />,
              },
              {
                path: '/dashboard/history:id',
                element: <ChartsDetails />,
              },
            ],
          },
          {
            path: '/',
            element: <Login />,
            children: [],
          },
          {
            path: '/redirect',
            element: <RedirectPage />,
            children: [],
          },
          {
            path: '/restore/:id',
            element: <Restore />,
            children: [],
          },
        ],
      },
      {
        path: '*',
        element: <RedirectPage />,
        children: [],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
