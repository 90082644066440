import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Button, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect } from 'react';
import axios from 'axios';
import useWebSocket from 'react-use-websocket';
import { MdStop, MdPlayArrow } from 'react-icons/md';
import { Oval } from 'react-loader-spinner';

import DeviceList from '../components/device/DeviceList';
import PatientList from '../components/user/PatientList';
import DeviceBleList from '../components/history/DeviceBleList';

import styles from './EnvironmentDetails.module.css';

import configData from '../config/config';
import constant from '../config/constant';

import './../mainStyle.scss';

import { ShowAlert, axiosError } from '../utilities';

const getParentString = (node) => {
  if (!node.parent) {
    return (
      <Link to={'/dashboard/environments/' + node.id}>
        <Button variant="primary">{node.description}</Button>
      </Link>
    );
  }

  return (
    <>
      {getParentString(node.parent)}
      <Form.Label>-</Form.Label>
      <Link to={'/dashboard/environments/' + node.id}>
        <Button variant="primary">{node.description}</Button>
      </Link>
    </>
  );
};

function EnvironmentsDetails(props) {
  const [environment, setEnvironment] = useState({});
  const params = useParams();

  const [bleDevices, setBleDevices] = useState([]);
  const [emptyDevice, setEmptyDevice] = useState(false);
  const [emptyPatient, setEmptyPatient] = useState(false);

  const [showLoading, setShowLoading] = useState(false);
  const [isCollectingReal, setIsCollectingReal] = useState(false);

  const navigate = useNavigate();

  const { getWebSocket } = useWebSocket(configData.SOCKET_URL, {
    onOpen: () => {
      console.log('WebSocket open');

      let obj = {
        type: 'webapp',
        data: params.id,
      };

      console.log(JSON.stringify(obj));
      getWebSocket().send(JSON.stringify(obj));
    },
    onMessage: (msg) => {
      console.log(msg);

      let obj = JSON.parse(msg.data);
      console.log('found device:', obj, params);

      if (obj.idEnvironment === parseInt(params.id)) {
        // obj["data"]
        setBleDevices(obj.data);
        setShowLoading(false);
      }
    },
    onClose: () => {
      console.log('WebSocket close');
      setShowLoading(false);
    },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showLoading === true) {
        setShowLoading(false);
        ShowAlert('Scan Ble timeout');
      }
    }, constant.timeOutGetScanBleMs);

    return () => clearTimeout(timeout);
  }, [showLoading]);

  useEffect(() => {
    async function getEnvironment() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/environment/' + params.id,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setEnvironment(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }
    async function getAllBle() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/device/ble',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setBleDevices(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    getEnvironment();
    if (environment.isObject) {
      getAllBle();
    }
  }, [environment.isObject, navigate, params.id]);

  useEffect(() => {
    async function refresh() {
      try {
        const response = await axios.get(
          configData.SERVER_URL + '/environment/' + params.id + '/collecting',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setIsCollectingReal(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    if (environment.isCollecting) {
      const interval = setInterval(refresh, 1000);
      return () => clearInterval(interval);
    }
  });

  async function startDataCollection(idEnvironment) {
    try {
      await axios
        .post(
          configData.SERVER_URL + '/environment/' + idEnvironment + '/start',
          {},
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  async function stopDataCollection(idEnvironment) {
    try {
      await axios
        .delete(
          configData.SERVER_URL + '/environment/' + idEnvironment + '/stop',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  if (!environment) {
    return (
      <Container>
        <h1>Could not found this environment</h1>

        <Link to="..">
          <Button variant="primary"></Button>
        </Link>
      </Container>
    );
  } else {
    return (
      <Container>
        {showLoading && <div className={styles.backdrop}></div>}
        {showLoading && (
          <div className={styles.spinnerClass}>
            <Oval
              visible="true"
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}

        <br />
        {environment && (
          <>
            <h1>You are now managing "{environment.description}"</h1>
            {getParentString(environment)}
          </>
        )}

        <br />
        <br />

        {environment && !environment.isObject && environment.isCollecting && (
          <>
            <Button
              variant="danger"
              onClick={() => stopDataCollection(params.id)}
              className="btn-danger">
              <MdStop /> Stop data collection
            </Button>

            <br />
          </>
        )}
        {environment && !environment.isObject && !environment.isCollecting && (
          <>
            <Button
              variant="success"
              onClick={() => startDataCollection(params.id)}
              className="btn-primary"
              disabled={emptyPatient && emptyDevice}>
              <MdPlayArrow /> Start data collection
            </Button>

            <br />
          </>
        )}
        {environment &&
          !environment.isObject &&
          environment.isCollecting &&
          isCollectingReal && (
            <>
              <Spinner animation="grow" variant="success" /> Receiving data...
            </>
          )}
        {environment &&
          !environment.isObject &&
          environment.isCollecting &&
          !isCollectingReal && (
            <>
              <Spinner animation="grow" variant="danger" /> Not receiving data
            </>
          )}

        <br />
        <br />

        {environment && !environment.isObject && (
          <Card className="overflow-auto">
            <Card.Header>Device list</Card.Header>
            <Card.Body>
              <DeviceList
                idEnvironment={params.id}
                disabled={environment.isCollecting}
                deviceIsEmpty={(e) => setEmptyDevice(e)}
                startBle={(e) => setShowLoading(true)}
              />
            </Card.Body>
          </Card>
        )}

        <br />
        <Card className="overflow-auto">
          <Card.Header>Ble device list</Card.Header>
          <Card.Body>
            {
              // Leaf page
            }
            {environment && environment.isObject && (
              <DeviceBleList
                idEnvironment={params.id}
                newDevice={bleDevices.length > 0 ? bleDevices : null}
                showAdd
                disabled={environment.isCollecting}
              />
            )}

            {
              // Raspberry scan page
            }
            {environment && !environment.isObject && (
              <DeviceBleList
                newDevice={bleDevices.length > 0 ? bleDevices : null}
                idEnvironment={params.id}
                disabled={environment.isCollecting}
              />
            )}
          </Card.Body>
        </Card>

        {environment && !environment.isObject && (
          <>
            <br />
            <Card className="overflow-auto">
              <Card.Header>Patient list</Card.Header>
              <Card.Body>
                <PatientList
                  idEnvironment={params.id}
                  disabled={environment.isCollecting}
                  patientIsEmpty={(e) => setEmptyPatient(e)}
                />
              </Card.Body>
            </Card>
          </>
        )}
        <br />
      </Container>
    );
  }
}

export default EnvironmentsDetails;
