import { Form, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import styles from './NewEnvironment.module.css';

import configData from '../config/config';
import { useState, useRef, useEffect } from 'react';
import { axiosError } from '../utilities';

function NewEnvironment() {
  const navigate = useNavigate();
  const params = useParams();

  const [environmentName, setEnvironmentName] = useState('');

  useEffect(() => {
    async function getEnvironmentName() {
      if (params.parent === undefined) {
        setEnvironmentName('Root');
        return;
      }

      try {
        const response = await axios.get(
          configData.SERVER_URL + '/environment/' + params.parent,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setEnvironmentName(data.description);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    getEnvironmentName();
  }, [navigate, params.parent]);

  const handleClose = () => navigate('..');

  const ref = useRef();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      ref.current.focus();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let envData = {
      description: description,
      isFixed: isFixed,
      isObject: isObject,
    };

    if (!isObject) {
      envData.isFixed = false;
    }

    if (params && params.parent) {
      envData.parent = params.parent;
    } else {
      envData.parent = null;
    }

    console.log(envData);

    try {
      await axios.post(configData.SERVER_URL + '/environment', envData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('token')).token
          }`,
        },
      });
    } catch (error) {
      axiosError(navigate, error);
    }

    navigate(0);
  };

  const [description, setDescription] = useState('');
  const [isObject, setIsObject] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  return (
    <>
      <Modal show onHide={handleClose}>
        <Form
          method="post"
          className={styles.form}
          onKeyDown={handleKeyDown}
          onSubmit={handleSubmit}>
          <h2>{environmentName}</h2>
          <hr />
          <div className="form-group">
            <label htmlFor="description">Name</label>
            <input
              className="form-control"
              id="description"
              name="description"
              placeholder="Office, Table, Water Bottle, ..."
              required
              minLength="1"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="isObject"
              name="isObject"
              value={isObject}
              onChange={(e) => setIsObject(e.target.checked)}
            />
            <label htmlFor="isObject" className="form-check-label">
              Is an object
            </label>
          </div>
          <br />
          {isObject && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="isFixed"
                name="isFixed"
                value={isFixed}
                onChange={(e) => setIsFixed(e.target.checked)}
              />
              <label htmlFor="isFixed" className="form-check-label">
                Is fixed
              </label>
            </div>
          )}

          <br />
          <div>
            <Link to="..">
              <button className="btn btn-danger">Cancel</button>
            </Link>
            <button className="btn btn-primary spl15" type="submit" ref={ref}>
              Submit
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default NewEnvironment;
