import { Outlet } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';

import DeviceList from '../components/device/DeviceList';

function Devices() {
  return (
    <>
      <Outlet />

      <Container>
        <Card className="overflow-auto">
          <Card.Header>List devices</Card.Header>
          <Card.Body>
            <DeviceList showEnvironment />
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Devices;
