import { Card, Container } from 'react-bootstrap';
import DiaryList from '../components/diary/DiaryList';
import { useState } from 'react';

import './../mainStyle.scss';

function Diary() {
  var now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  var nowSub = new Date();
  nowSub.setMinutes(now.getMinutes() - now.getTimezoneOffset() - 5);

  const [dateTimeStart, setDateTimeStart] = useState(
    nowSub.toISOString().slice(0, 16),
  );
  const [dateTimeEnd, setDateTimeEnd] = useState(
    now.toISOString().slice(0, 16),
  );

  return (
    <Container>
      <Card className="overflow-auto">
        <Card.Header>Diary list</Card.Header>
        <Card.Body>
          <p>Start Date</p>
          <input
            aria-label="Date and time"
            type="datetime-local"
            value={dateTimeStart}
            onChange={(e) => setDateTimeStart(e.target.value)}
          />
          <p>End date</p>
          <input
            aria-label="Date and time"
            type="datetime-local"
            value={dateTimeEnd}
            onChange={(e) => setDateTimeEnd(e.target.value)}
          />

          <hr />

          <DiaryList dateTimeStart={dateTimeStart} dateTimeEnd={dateTimeEnd} />
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Diary;
