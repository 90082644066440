import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Logout() {
  let navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('token');
    navigate('../..');
  }, [navigate]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default Logout;
