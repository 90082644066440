import { Outlet } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';

import PatientList from '../components/user/PatientList';

function Charts() {
  return (
    <>
      <Outlet />

      <Container>
        <Card className="overflow-auto">
          <Card.Header>Patient list</Card.Header>
          <Card.Body>
            <PatientList charts />
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Charts;
