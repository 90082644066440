import { useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Form, Stack, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import styles from './Login.module.css';
import MainHeader from '../components/MainHeader';

import configData from '../config/config';
import { axiosError } from '../utilities';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [email, setEmail] = useState('');

  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRestoreMessage, setShowRestoreMessage] = useState(false);

  function isValidForm() {
    return username.length > 0 && password.length > 0;
  }

  const handleClose = (e) => setShowError(false);

  function handleCloseMessage() {
    setShowModal(false);
    setShowRestoreMessage(false);
  }

  let navigate = useNavigate();
  async function handleSubmit(event) {
    event.preventDefault();

    const loginData = {
      username: username,
      password: password,
    };

    try {
      const data = await axios.post(
        configData.SERVER_URL + '/login',
        loginData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (data) {
        let tokenData = await data.data;
        console.log(tokenData);
        localStorage.setItem('token', JSON.stringify(tokenData));
        navigate('/dashboard');
      } else {
        setShowError(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setShowError(true);
      } else {
        axiosError(navigate, error);
      }
    }
  }

  async function restoreMethod() {
    try {
      await axios.post(
        configData.SERVER_URL + '/user/' + email + '/restore',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    } catch (error) {
      // The backend always respond with a 404
      //axiosError(navigate, error);
    }

    setShowRestoreMessage(true);
  }

  const ref = useRef();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      ref.current.focus();
    }
  };

  function showRestoreModal() {
    setShowModal(true);
  }

  function handleCloseRestore() {
    setShowModal(false);
  }

  return (
    <>
      <Outlet />

      <MainHeader />

      <div className={styles.login}>
        <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
          <Stack gap={3}>
            <Form.Group controlId="text">
              <Form.Label>Username</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button type="submit" disabled={!isValidForm()} ref={ref}>
              Login
            </Button>

            {/*
            <Button onClick={showRestoreModal}>Restore</Button>
            */}
          </Stack>
        </Form>
      </div>

      <Modal show={showError} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login error</Modal.Title>
        </Modal.Header>
        <Modal.Body>The credentials inserted are incorrect.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRestoreMessage} onHide={handleCloseMessage}>
        <Modal.Header closeButton>
          <Modal.Title>Restore</Modal.Title>
        </Modal.Header>
        <Modal.Body>Email send</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseMessage}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleCloseRestore}>
        <Modal.Header closeButton>
          <Modal.Title>Insert email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control"
            type="email"
            id="email"
            name="email"
            placeholder="john.smith@email.com"
            required
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={restoreMethod}>
            Restore
          </Button>
          <Button variant="danger" onClick={handleCloseRestore}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Login;
