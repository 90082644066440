import { Outlet } from 'react-router-dom';
import { IsBeta, GetTopBarString } from '../utilities';

import TopBar from '../components/topbar/TopBar';

function RootLayout() {
  return (
    <>
      {IsBeta() && (
        <>
          <TopBar text={GetTopBarString()}></TopBar>
          <br />
        </>
      )}
      <Outlet />
    </>
  );
}

export default RootLayout;
