import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { TiExport } from 'react-icons/ti';
import { MdOutlineDeviceHub } from 'react-icons/md';
import { FaUsersGear } from 'react-icons/fa6';
import { AiOutlineLineChart } from 'react-icons/ai';

import './../mainStyle.scss';

function Dashboard() {
  return (
    <>
      <div className="container txtCenter">
        <br />
        <div className="row">
          <h2>Select a menu</h2>
          <div className="col" />
          <div className="col-sm-5">
            <Link to="users" className="noTextDec">
              <Card className="dashboardCardUser">
                <Card.Body>
                  <Card.Title>
                    <FaUsersGear size={70} />
                  </Card.Title>
                  <Card.Text>Users</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </div>
          <div className="col-sm-5">
            <Link to="environments" className="noTextDec">
              <Card className="dashboardCardAmbient">
                <Card.Body>
                  <Card.Title>
                    <MdOutlineDeviceHub size={70} />
                  </Card.Title>
                  <Card.Text>Environment</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </div>
          <div className="col" />
        </div>
        <div className="row">
          <div className="col" />
          <div className="col-sm-5">
            <Link to="charts" className="noTextDec">
              <Card className="dashboardCardChart">
                <Card.Body>
                  <Card.Title>
                    <AiOutlineLineChart size={70} Environments />
                  </Card.Title>
                  <Card.Text>Charts</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </div>
          <div className="col-sm-5">
            <Link to="export" className="noTextDec">
              <Card className="dashboardCardExport">
                <Card.Body>
                  <Card.Title>
                    <TiExport size={70} />
                  </Card.Title>
                  <Card.Text>Export</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </div>

          <div className="col" />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
