import { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import configData from '../../config/config';
import { axiosError } from '../../utilities';

function DiaryList(props) {
  const [diary, setDiary] = useState([
    {
      id: 1,
      timeStamp: 1717148527000,
      patient: { name: 'Mario', surname: 'Rossi' },
      ambient: { name: 'Cucina' },
      beaconData: { rssi: -30 },
    },
    {
      id: 2,
      timeStamp: 1717148627000,
      patient: { name: 'Mario', surname: 'Rossi' },
      ambient: { name: 'Balcone' },
      beaconData: { rssi: -50 },
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchDiary() {
      try {
        // const response = await axios.get(
        //   configData.SERVER_URL +
        //     '/diary/' +
        //     props.dateTimeStart +
        //     '/' +
        //     props.dateTimeEnd,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${
        //         JSON.parse(localStorage.getItem('token')).token
        //       }`,
        //     },
        //   },
        // );
        // const data = await response.data;
        // setDiary(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    fetchDiary();
  }, [props.idEnvironment, navigate, props]);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Date</th>
          <th>Patient</th>
          <th>Environment</th>
          <th>Rssi</th>
        </tr>
      </thead>
      <tbody>
        {diary &&
          diary
            .sort((a, b) =>
              a.timeStamp > b.timeStamp
                ? 1
                : a.timeStamp < b.timeStamp
                  ? -1
                  : 0,
            )
            .map((u) => (
              <tr key={u.id}>
                <td>{new Date(u.timeStamp).toLocaleString()}</td>
                <td>
                  {u.patient.name} - {u.patient.surname}
                </td>
                <td>{u.ambient.name}</td>
                <td>{u.beaconData.rssi}</td>
              </tr>
            ))}
      </tbody>
    </Table>
  );
}

export default DiaryList;
