import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  MdConnectWithoutContact,
  MdDelete,
  MdAutoGraph,
  MdClose,
  MdSave,
  MdSettings,
} from 'react-icons/md';
import axios from 'axios';
import '../../mainStyle.scss';

import configData from '../../config/config';
import { axiosError } from '../../utilities';

function PatientList(props) {
  const [allPatient, setAllPatient] = useState([]);
  const [environmentPatient, setEnvironmentPatient] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [ble, setBle] = useState(false);
  const [gps, setGPS] = useState(false);
  const [heartRate, setHeartRate] = useState(false);
  const [imu, setIMU] = useState(false);
  const [hz, setHz] = useState(0);
  const [idDeviceSensor, setIdDeviceSensor] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAllPatient() {
      try {
        let url;
        if (props.idEnvironment) {
          url = configData.SERVER_URL + '/user/patient/' + props.idEnvironment;
        } else {
          url = configData.SERVER_URL + '/user/patient';
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        });

        const data = await response.data;
        setAllPatient(data);
      } catch (error) {
        axiosError(navigate, error);
      }
    }
    async function fetchEnvironmentPatient() {
      try {
        const response = await axios.get(
          configData.SERVER_URL +
            '/environment/' +
            props.idEnvironment +
            '/patient',
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        );

        const data = await response.data;
        setEnvironmentPatient(data);

        if (props.patientIsEmpty !== undefined) {
          props.patientIsEmpty(data.length === 0);
        }
      } catch (error) {
        axiosError(navigate, error);
      }
    }

    fetchAllPatient();

    if (!props.charts) {
      fetchEnvironmentPatient();
    }
  }, [props.idEnvironment, props.charts, navigate, props]);

  async function associatePatientToEnvironment(myUser, idUser, idEnvironment) {
    try {
      await axios
        .post(
          configData.SERVER_URL +
            '/environment/' +
            myUser +
            '/' +
            idUser +
            '/' +
            idEnvironment,
          {},
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  async function removeAssociationPatientToEnvironment(
    myUser,
    idUser,
    idEnvironment,
  ) {
    try {
      await axios
        .delete(
          configData.SERVER_URL +
            '/environment/' +
            myUser +
            '/' +
            idUser +
            '/' +
            idEnvironment,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          navigate(0);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  function isInList(idPatient) {
    for (let index = 0; index < environmentPatient.length; index++) {
      const element = environmentPatient[index];

      if (Number(element.id) === Number(idPatient)) {
        return true;
      }
    }

    return false;
  }

  function handleClose() {
    setShowModal(false);
  }

  async function showSensor(idDevice) {
    try {
      const response = await axios.get(
        configData.SERVER_URL + '/device/' + idDevice + '/sensor',
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        },
      );

      const response2 = await axios.get(
        configData.SERVER_URL + '/device/info/' + idDevice,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('token')).token
            }`,
          },
        },
      );

      const data = await response.data;
      setBle(data.ble);
      setGPS(data.gps);
      setHeartRate(data.heartRate);
      setIMU(data.imu);

      setHz(response2.data.dataCollectionHz);

      setIdDeviceSensor(idDevice);
      setShowModal(true);
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  async function sendBooleans() {
    try {
      let data = { ble: ble, gps: gps, heartRate: heartRate, imu: imu };

      await axios
        .post(
          configData.SERVER_URL + '/device/' + idDeviceSensor + '/sensor',
          data,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          setShowModal(false);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  async function sendHz() {
    try {
      await axios
        .post(
          configData.SERVER_URL + '/device/' + idDeviceSensor + '/hz/' + hz,
          {},
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          setShowModal(false);
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  async function closeModalSensor() {
    sendBooleans();
    sendHz();

    setShowModal(false);
  }

  const associatedUppeTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      The patient is already associated in an upper environment
    </Tooltip>
  );

  const patientCollectingTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      The wearos of the patient is collecting
    </Tooltip>
  );

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Set Wearable Sensor</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Check
              type="switch"
              id="BLE"
              label="BLE (Bluetooth Low Energy)"
              name="BLE"
              defaultChecked={ble}
              onChange={(e) => setBle((state) => !state)}
            />

            <Form.Check
              type="switch"
              id="GPS"
              label="GPS (not available)"
              name="GPS"
              defaultChecked={gps}
              onChange={(e) => setGPS((state) => !state)}
            />

            <Form.Check
              type="switch"
              id="HearthRate"
              label="HeartRate"
              name="HearthRate"
              defaultChecked={heartRate}
              onChange={(e) => setHeartRate((state) => !state)}
            />

            <Form.Check
              type="switch"
              id="IMU"
              label="IMU"
              name="IMU"
              defaultChecked={imu}
              onChange={(e) => setIMU((state) => !state)}
            />

            <br />

            <Form.Label>Data Collection Hz</Form.Label>

            <Form.Control
              type="number"
              onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
              defaultValue={hz}
              onChange={(e) => setHz(e.target.value)}
            />
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose} className="btn-danger">
            <MdClose /> Close
          </Button>
          <Button
            variant="success"
            onClick={closeModalSensor}
            className="btn-primary">
            <MdSave /> Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Username</th>
            <th>Name</th>
            <th>Surname</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allPatient
            .sort((a, b) =>
              a.username > b.username ? 1 : a.username < b.username ? -1 : 0,
            )
            .map((u) => (
              <tr key={u.id}>
                <td>{u.username}</td>
                <td>{u.name}</td>
                <td>{u.surname}</td>
                <td>
                  {!props.charts &&
                    environmentPatient &&
                    isInList(u.id) &&
                    !u.upper && (
                      <Button
                        variant="danger"
                        onClick={() =>
                          removeAssociationPatientToEnvironment(
                            JSON.parse(localStorage.getItem('token')).id,
                            u.id,
                            props.idEnvironment,
                          )
                        }
                        disabled={props.disabled || u.upper}>
                        <MdDelete />
                        Remove from the environment
                      </Button>
                    )}
                  {!props.charts &&
                    environmentPatient &&
                    isInList(u.id) &&
                    u.upper && (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={associatedUppeTooltip}>
                        <span className="d-inline-block">
                          <Button disabled variant="danger">
                            <MdDelete />
                            Remove from the environment
                          </Button>
                        </span>
                      </OverlayTrigger>
                    )}

                  {!props.charts &&
                    environmentPatient &&
                    !isInList(u.id) &&
                    !u.upper &&
                    !u.isCollecting && (
                      <Button
                        variant="primary"
                        onClick={() =>
                          associatePatientToEnvironment(
                            JSON.parse(localStorage.getItem('token')).id,
                            u.id,
                            props.idEnvironment,
                          )
                        }
                        disabled={props.disabled || u.upper || u.isCollecting}>
                        <MdConnectWithoutContact />
                        Add to environment
                      </Button>
                    )}
                  {!props.charts &&
                    environmentPatient &&
                    !isInList(u.id) &&
                    (u.upper || u.isCollecting) && (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          u.isCollecting
                            ? patientCollectingTooltip
                            : associatedUppeTooltip
                        }>
                        <span className="d-inline-block">
                          <Button disabled variant="primary">
                            <MdConnectWithoutContact />
                            Add to environment
                          </Button>
                        </span>
                      </OverlayTrigger>
                    )}

                  {!props.charts && u.idDevice !== null && (
                    <Button
                      variant="success"
                      onClick={() => showSensor(u.idDevice)}
                      className="btn-primary"
                      disabled={props.disabled}>
                      <MdSettings /> Set wearable sensor
                    </Button>
                  )}

                  {props.charts && (
                    <Link to={'/dashboard/charts/' + u.id}>
                      <Button variant="primary">
                        <MdAutoGraph /> View data
                      </Button>
                    </Link>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default PatientList;
