import styles from './MainHeader.module.css';

function MainHeader() {
  return (
    <header className={styles.header}>
      <h1>
        <img
          className={styles.logoHeader}
          src={require('./../assets/logo.png')}
          alt=""
        />
        Smart Object 4 Smart Health
      </h1>
    </header>
  );
}

export default MainHeader;
