import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';

import configData from '../config/config';
import { axiosError } from '../utilities';

function Restore() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');

  async function sendNewPassword(id) {
    try {
      await axios
        .post(
          configData.SERVER_URL + '/user/' + id + '/password',
          { password: newPassword },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('token')).token
              }`,
            },
          },
        )
        .then(() => {
          navigate('..');
        });
    } catch (error) {
      axiosError(navigate, error);
    }
  }

  return (
    <>
      <Outlet />

      <Container>
        <Card className="overflow-auto">
          <Card.Header>Password restore</Card.Header>
          <Card.Body>
            Code {id}
            <br />
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                className="form-control"
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                minLength="8"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <Button variant="primary" onClick={() => sendNewPassword(id)}>
              Submit
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Restore;
